import React from 'react'
import IndustrySidebar from './IndustrySidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const IndustryDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

                        <div className="services-details-desc pt-100">
                            <span className="sub-title">Industries</span>
                            <h3>Technology Industry</h3>
                            <p>As executives in the technology space well know, running software, hardware and technology service businesses presents unique management challenges. We help to understand business challenges and discover opportunities to tackle them with digital tools.</p>
                             <h3>Retail Industry</h3>
                            <p>We help retailers to improve their digital presence and promote their offerings.</p>
                              <h3>Financial Services Industry</h3>
                                <p>We help financial institutions to manage risks and serve customers.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Sector Expertise </h3>
                                        <ul>
                                        <li>Fashion and Luxury</li>
                                        <li>Furniture</li>
                                        <li>LifeSciences</li>
                                        <li>Regulated Marketplaces</li>
                                        <li>Dropshipping</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <p>We can help you to manage a single instance or scale to thousands using AWS services. These services help you make the most of flexible compute resources by simplifying provisioning, configuration, and scaling.</p> */}
                            <h3>Applications We Cover</h3>

                            <div className="row">

                            <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        SAP
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Salesforce
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Magento
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        OpenCart
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Shopify
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Contentful
                                    </div>
                                </div>
            
                            </div>

                            <h3>Technologies We Use</h3>
                            <ul className="technologies-features">
                                <li><span>ReactJS</span></li>
                                <li><span>GatsbyJS</span></li>
                                <li><span>NodeJS</span></li>
                                <li><span>PHP</span></li>
                                <li><span>SQL</span></li>
                                <li><span>MongoDB</span></li>
                            </ul>
                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <IndustrySidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default IndustryDetailsContent