import React from 'react'
import {Link} from 'gatsby'

const ServiceSidebar = () => {
    return (
        <div className="services-details-info pt-100">
            <div className="download-file">
                <h3>Services</h3>
            <ul className="services-list">
                <li>
                    <Link to="/capabilities/marketplace" className="active">
                        Markerplaces
                    </Link>
                </li>
                <li>
                    <Link to="/capabilities/ecommerce">
                        E-Commerce Strategy
                    </Link>
                </li>
                {/* <li>
                    <Link to="/capabilities/crm">
                        CRM
                    </Link>
                </li> */}
                <li>
                    <Link to="/capabilities/erp">
                        ERP
                    </Link>
                </li>
                <li>
                    <Link to="/capabilities/digital">
                        Digital Marketing
                    </Link>
                </li>
            </ul>
            </div>
            {/* <div className="download-file">
                <h3>Brochures</h3>

                <ul>
                    <li>
                        <Link to="#">
                            PDF Download <i className='bx bxs-file-pdf'></i>
                        </Link>
                    </li>
                    <li>
                        <Link to="#">
                            Services Details.txt <i className='bx bxs-file-txt'></i>
                        </Link>
                    </li>
                </ul>
            </div> */}

            <div className="services-contact-info">
                <h3>Contact Info</h3>
                
                <ul>
                    {/* <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Phone:</span>
                        <a href="tel:+21453545413">+2145 354 5413</a>
                    </li> */}
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        1412 Broadway, 21st Fl, New York City, New York 10018
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-envelope'></i>
                        </div>
                        <span>Email:</span>
                        <a href="mailto:contact@locron.com">contact@locron.com</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ServiceSidebar